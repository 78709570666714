import React from 'react';

const ProductList = (props) => {
    const { data } = props;
    var productText = "Ürünler";
    if(data.productList.length === 1){
        productText = 'Ürün'
    }

    var headText = "Satın Aldığınız " + productText + " 🎁";
    if(data.statusNumeric === 7){
        headText = "Siparişiniz İçin Yorum Yapmayı Unutmayın 😊";
    }else if(data.statusNumeric === 4){
        headText = "İptal Ettiğiniz " + productText + " 😢";
    }

    return (
        <div className="container" id="productList">
            <h5 className="text-center">
                {
                    headText
                }
            </h5>
            <div className="row">
                {
                    data.productList.map(function(item) {
                        return <div className="col item text-center">
                            <a href={"https://www.ayakkabionline.com/" + item.link} target="_blank">
                                <img src={item.images} />
                                <span className="d-block" id="product-title">{item.title}</span>
                            </a>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default ProductList;