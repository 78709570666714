import React from 'react';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import ProductList from './ProductList';

const List = (props) => {
    function showOrderDetail(){
        document.getElementById('orderInfo').style.display = 'block';
        document.getElementById('productList').style.display = 'block';
        document.getElementById('cargoInfo').style.display = 'none';
        document.getElementById('order-detail-button').classList.add('active');
        document.getElementById('cargo-detail-button').classList.remove('active');
    }
    function showCargoDetail(){
        document.getElementById('orderInfo').style.display = 'none';
        document.getElementById('productList').style.display = 'none';
        document.getElementById('cargoInfo').style.display = 'block';
        document.getElementById('order-detail-button').classList.remove('active');
        document.getElementById('cargo-detail-button').classList.add('active');
    }

    const { data } = props;
    if (!data || data.length === 0 || !data.status){
        return (
            <div>
                <div className="container jumbotron text-center" id="orderInfo">
                    <h3>Sipariş Bulunamadı</h3>
                    <p>Verilen bilgiye ait sipariş bulunamamıştır.</p>
                </div>
                <Link to="/" className="home-button">
                    <i className="fa fa-home"></i> Ana Sayfaya Dön
                </Link>
            </div>
        );
    }else{
        return (
            <div>
                {
                    data.result.trackingUrl ?
                        <div className="btn-group detail-buttons">
                            <a className="btn btn-secondary active" id="order-detail-button" onClick={showOrderDetail}><i className="fa fa-shopping-cart"></i> Sipariş Durumu</a>
                            <a className="btn btn-secondary" id="cargo-detail-button" onClick={showCargoDetail}><i className="fa fa-truck"></i> Kargo Takip</a>
                        </div>
                    :
                        <p></p>
                }
                <div className="container jumbotron text-center" id="orderInfo">
                    <table width="100%" border="0">
                        <tr>
                            <td align="right" width="50%"><b>Ad Soyad:</b></td>
                            <td align="left" width="50%">{ data.result.customerName }</td>
                        </tr>
                        <tr>
                            <td align="right" width="50%"><b>Sipariş No:</b></td>
                            <td align="left" width="50%">{ data.result.orderNumber }</td>
                        </tr>
                        <tr>
                            <td align="right" width="50%"><b>Durumu:</b></td>
                            <td align="left" width="50%">
                                { data.result.status }
                                {
                                    data.result.statusExplanation !== "" ?
                                        <a id="button-tooltip" data-tip={ data.result.statusExplanation } style={{ marginLeft: 5 }}>
                                            <ReactTooltip />
                                            <i className="fa fa-info-circle"></i>
                                        </a>
                                    :
                                    <b></b>
                                }
                            </td>
                        </tr>
                        
                        {
                            data.result.cargoCompany ?
                                <tr>
                                    <td align="right" width="50%"><b>Kargo Firması:</b></td>
                                    <td align="left" width="50%">{ data.result.cargoCompany }</td>
                                </tr>
                            :
                                <p></p>
                        }
                        
                        {
                            data.result.trackingNumber ?
                                <tr>
                                    <td align="right" width="50%"><b>Takip Numarası:</b></td>
                                    <td align="left" width="50%">{ data.result.trackingNumber }</td>
                                </tr>
                            :
                                <p></p>
                        }
                    </table>

                </div>

                <div className="cargo-info" id="cargoInfo">
                    {
                        data.result.trackingUrl ?
                            <iframe src={data.result.trackingUrl} id="cargoStatus"></iframe>
                        :
                            <p></p>
                    }
                </div>

                <ProductList data={data.result} />

                <Link to="/" className="home-button" style={{ marginTop: 30 }}>
                    <i className="fa fa-home"></i> Ana Sayfaya Dön
                </Link>
            </div>
        );
    }
};

export default List;