import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useParams } from "react-router";
import TrackingPage from './components/TrackingPage';
import NoContent from './components/NoContent';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/:id">
            <TrackingPage />
          </Route>
          <Route exact path="/">
            <NoContent />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
