import React from 'react';
import { withRouter } from 'react-router';
import Footer from './Footer';
import logo from '../assets/img/logo.png';

class Tracking extends React.Component {
  componentDidMount(){
      document.title = "Sipariş Takip | Ayakkabionline"
  }
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    this.props.history.push('/' + this.state.value);
    event.preventDefault();
  }
  render() {
    return (
      <div className="App">
        <header>
          <a href="https://www.ayakkabionline.com" target="_blank"><img src={logo} alt="ayakkabionline" /></a>
        </header>
        <div className="container jumbotron text-center search-content">
          <form onSubmit={this.handleSubmit}>
            <div className="input-group">
              <input type="text" className="form-control" name="ordernumber" onChange={this.handleChange} autoComplete="off" placeholder="Sipariş numarası ile sorgula" />
              <div className="input-group-append">
                <button className="btn" type="submit">
                  Sorgula
                </button>
              </div>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Tracking);
