import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import Details from './Details';
import Loading from './Loading';
import Footer from './Footer';
import logo from '../assets/img/logo.png';

const Tracking = () => {
  let location = useLocation();
  let { id } = useParams();
  const [appState, setAppState] = useState({
    loading: false,
    repos: null,
  });

  useEffect(() => {
    document.title = id + " Siparişi | Ayakkabionline";
    
    const analytics = window.firebase && window.firebase.analytics;

    if (typeof analytics === "function") {
      const page_path = location.pathname + location.search;
      analytics().setCurrentScreen(page_path);
      analytics().logEvent("page_view", { page_path });
    }

    setAppState({ loading: true });
    fetch("https://a10line.com/takip-api/getOrderDetails/?orderNumber=" + id, {
      method: "GET",
      headers: {
        "x-api-key": "IU90dG8yMDIxLkF5b25UYWtpcCE="
      },
      redirect: 'follow'   
    })
      .then((res) => res.json())
      .then((result) => {
        setAppState({ loading: false, data: result });
      });
  }, [location, setAppState]);

  return (
    <div>
      {
        appState.loading ?
          <Loading />
        :
        <div>
          <header>
            <a href="https://www.ayakkabionline.com" target="_blank"><img src={logo} alt="ayakkabionline" /></a>
          </header>
          <Details data={appState.data} />
          <Footer />
        </div>
      }
    </div>
  );
}

export default Tracking;
