import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase';
import 'firebase/analytics';

firebase.initializeApp({
  "apiKey": "AIzaSyDlrNtuKtdEuHd9q5KSrHaFeYEZb9q5QLM",
  "appId": "1:183080862117:web:9ac548e654ee2c2a70e84c",
  "authDomain": "takip-ayakkabionline.firebaseapp.com",
  "databaseURL": "",
  "measurementId": "G-HFHVDQQ7RP",
  "messagingSenderId": "183080862117",
  "projectId": "takip-ayakkabionline",
  "storageBucket": "takip-ayakkabionline.appspot.com"
});

firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
