import React from 'react';
import Lottie from 'react-lottie';
import * as loading from '../assets/lottie/loading.json';

const Loading = () => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: loading.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div className="d-flex vh-100">
            <div className="d-flex w-100 justify-content-center align-self-center">
                <Lottie options={defaultOptions} height={400} width={400} isClickToPauseDisabled={true}/>
            </div>
        </div>
    );
}

export default Loading;